import React from 'react'
import { compose } from 'recompose'

import DesktopContainer from 'layouts/DesktopContainer'
import PageLayout from 'layouts/PageLayout'
import ReadyToOrder from 'common/ReadyToOrder'
import { ContentSliderWrapper, ContentSlider } from './ContentSlider'
import { SectionsContainer, SectionContainer } from './styled'
import CuisinesSection from './CuisinesSection'
import HeroSection from './HeroSection'
import HomeVenuesSection from './HomeVenuesSection'
import Testimonials from './Testimonials'
import withReferralCodeHandler from './withReferralCodeHandler'
import withPrecintRedirect from 'enhancers/withPrecintRedirect'
import { MobileScreen, DesktopScreen } from 'utils/screen'
import meta from 'const/meta'
import { EmailConfirmBanner } from '../account/main/EditProfile/EditEmail'

function Homepage() {
  return (
    <PageLayout title={meta.title}>
      <EmailConfirmBanner />

      <HeroSection />

      <HomeVenuesSection />

      <ContentSliderWrapper>
        <DesktopContainer>
          <DesktopScreen>
            <ContentSlider />
          </DesktopScreen>
          <MobileScreen>
            <ContentSlider />
          </MobileScreen>
        </DesktopContainer>
      </ContentSliderWrapper>

      <SectionsContainer>
        <SectionContainer>
          <CuisinesSection />
        </SectionContainer>
      </SectionsContainer>

      <Testimonials />
      <ReadyToOrder />
    </PageLayout>
  )
}

export default compose(withReferralCodeHandler, withPrecintRedirect)(Homepage)
